<template>
  <section class="relative overflow-hidden text-white py-12 flex flex-col bg-zinc-800 justify-center rounded-lg">
    <div class="relative z-10 flex flex-col justify-center px-2 sm:px-4">
      <div class="container mx-auto flex flex-col items-center px-4">
        <h2 class="text-base sm:text-lg md:text-2xl font-bold mb-4 text-center animate-slideInUp">
          A gestão ideal para o seu negócio
        </h2>
        <button
          class="bg-orange-500 text-white font-semibold py-2 px-4 sm:py-3 sm:px-6 rounded-full shadow-lg hover:bg-orange-600 transition transform hover:scale-105 animate-bounce">
          Comece agora!
        </button>
      </div>

      <div
        class="container mx-auto mt-8 sm:mt-12 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 px-4">
        <div
          class="flex flex-col items-center justify-center border border-orange-500 rounded-md p-3 sm:p-4 space-y-1 sm:space-y-2 hover:shadow-xl transition-transform transform hover:scale-105 animate-fadeIn">
          <font-awesome-icon :icon="['fas', 'utensils']" class="h-6 w-6 sm:h-8 sm:w-8 text-orange-500" />
          <p class="font-semibold text-xs sm:text-sm">RESTAURANTE</p>
        </div>
        <div
          class="flex flex-col items-center justify-center border border-orange-500 rounded-md p-3 sm:p-4 space-y-1 sm:space-y-2 hover:shadow-xl transition-transform transform hover:scale-105 animate-fadeIn delay-100">
          <font-awesome-icon :icon="['fas', 'truck']" class="h-6 w-6 sm:h-8 sm:w-8 text-orange-500" />
          <p class="font-semibold text-xs sm:text-sm">DELIVERY</p>
        </div>
        <div
          class="flex flex-col items-center justify-center border border-orange-500 rounded-md p-3 sm:p-4 space-y-1 sm:space-y-2 hover:shadow-xl transition-transform transform hover:scale-105 animate-fadeIn delay-200">
          <font-awesome-icon :icon="['fas', 'store-alt']" class="h-6 w-6 sm:h-8 sm:w-8 text-orange-500" />
          <p class="font-semibold text-xs sm:text-sm">DARK KITCHENS</p>
        </div>
        <div
          class="flex flex-col items-center justify-center border border-orange-500 rounded-md p-3 sm:p-4 space-y-1 sm:space-y-2 hover:shadow-xl transition-transform transform hover:scale-105 animate-fadeIn delay-300">
          <font-awesome-icon :icon="['fas', 'hotdog']" class="h-6 w-6 sm:h-8 sm:w-8 text-orange-500" />
          <p class="font-semibold text-xs sm:text-sm">LANCHONETES</p>
        </div>
        <div
          class="flex flex-col items-center justify-center border border-orange-500 rounded-md p-3 sm:p-4 space-y-1 sm:space-y-2 hover:shadow-xl transition-transform transform hover:scale-105 animate-fadeIn delay-400">
          <font-awesome-icon :icon="['fas', 'hamburger']" class="h-6 w-6 sm:h-8 sm:w-8 text-orange-500" />
          <p class="font-semibold text-xs sm:text-sm">HAMBURGUERIAS</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faUtensils,
  faTruck,
  faStoreAlt,
  faHotdog,
  faHamburger,
} from '@fortawesome/free-solid-svg-icons';

library.add(faUtensils, faTruck, faStoreAlt, faHotdog, faHamburger);

export default {
  name: 'BusinessManagementSection',
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
@keyframes slideInUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes bounce {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-5px);
  }
}

.animate-slideInUp {
  animation: slideInUp 0.8s ease-out forwards;
}

.animate-fadeIn {
  animation: fadeIn 0.8s ease-out forwards;
}

.delay-100 {
  animation-delay: 0.1s;
}

.delay-200 {
  animation-delay: 0.2s;
}

.delay-300 {
  animation-delay: 0.3s;
}

.delay-400 {
  animation-delay: 0.4s;
}

.animate-bounce {
  animation: bounce 1.5s infinite;
}

.container {
  max-width: 1200px;
}

button {
  transition: all 0.3s ease;
}

.flex-1 {
  flex: 1;
}
</style>
