<template>
  <section class="plans-section">
    <h2 class="section-title">
      Escolha o melhor plano para seu negócio
    </h2>
    <div class="plans-container">
      <PlanCard title="Plano Básico" description="Ideal para pequenos restaurantes." :features="[
        'Gerenciamento de Pedidos',
        'Atendimento à Mesa',
        'Relatórios Básicos'
      ]" buttonText="Escolher Plano Básico" :iconName="'utensils'" :iconColor="'bg-orange-500'"
        :textColor="'text-orange-500'" :buttonClass="'btn-primary'" :bgColor="'bg-zinc-800 text-white'" />

      <PlanCard title="Plano Empresarial" description="A melhor opção para grandes operações de delivery." :features="[
        'Gerenciamento de Pedidos Avançado',
        'Atendimento Multicanal',
        'Relatórios Detalhados',
        'Suporte Prioritário'
      ]" buttonText="Escolher Plano Empresarial" :recommended="true" :iconName="'th-large'"
        :iconColor="'bg-white text-orange-500'" :textColor="'text-white'" :buttonClass="'btn-secondary'"
        :bgColor="'bg-orange-500 text-white'" />

      <PlanCard title="Plano Profissional" description="Perfeito para dark kitchens e negócios de médio porte."
        :features="[
          'Gerenciamento Completo de Pedidos',
          'Integração com Marketplaces',
          'Relatórios Personalizados',
          'Suporte 24/7'
        ]" buttonText="Escolher Plano Profissional" :iconName="'store-alt'" :iconColor="'bg-orange-500'"
        :textColor="'text-orange-500'" :buttonClass="'btn-primary'" :bgColor="'bg-zinc-800 text-white'" />
    </div>
  </section>
</template>

<script>
import PlanCard from './PlanCard.vue';

export default {
  name: 'PlansSection',
  components: {
    PlanCard,
  },
};
</script>

<style scoped>
.plans-section {
  padding: 4rem 2rem;
  background-color: #f9fafb;
}

.section-title {
  text-align: center;
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 3rem;
  color: #1f2937;
}

.plans-container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
@media (max-width: 768px) {
  .section-title {
    font-size: 2rem;
  }

  .plans-container {
    grid-template-columns: 1fr;
  }
}
</style>
