// main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import '../src/css/main.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

import {
    faCogs,
    faMapMarkerAlt,
    faChartLine,
    faHeadset,
    faFileAlt,
    faChevronLeft,
    faChevronRight,
    faUtensils,
    faTruck,
    faStoreAlt,
    faHotdog,
    faHamburger,
    faThLarge,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

import {
    faFacebookF,
    faTwitter,
    faInstagram,
    faLinkedinIn,
    faWhatsapp
} from '@fortawesome/free-brands-svg-icons';

library.add(
    faCogs,
    faMapMarkerAlt,
    faChartLine,
    faHeadset,
    faFileAlt,
    faChevronLeft,
    faChevronRight,
    faUtensils,
    faTruck,
    faStoreAlt,
    faHotdog,
    faHamburger,
    faThLarge,
    faCheckCircle,
    faFacebookF,
    faTwitter,
    faInstagram,
    faLinkedinIn,
    faWhatsapp
);

const app = createApp(App);

app.use(router);

app.component('font-awesome-icon', FontAwesomeIcon);

app.mount('#app');
