<template>
  <section class="text-white min-h-screen flex flex-col justify-center bg-zinc-800 px-4">
    <h2 class="text-center text-xl md:text-3xl lg:text-4xl font-bold mb-10">
      Tire suas dúvidas com as perguntas frequentes
    </h2>
    <div class="faq-container max-w-4xl mx-auto">
      <div v-for="(faq, index) in faqs" :key="index" class="faq-item border border-orange-500 rounded-lg mb-4">
        <button class="faq-question w-full text-left flex justify-between items-center px-4 py-3 focus:outline-none"
          @click="toggleFaq(index)">
          <span class="text-base md:text-lg lg:text-xl">{{ index + 1 }}. {{ faq.question }}</span>
          <font-awesome-icon :icon="[openIndex === index ? 'fas' : 'fas', 'chevron-down']" class="text-orange-500" />
        </button>
        <div v-if="openIndex === index" class="faq-answer px-4 py-3 text-gray-300 text-sm md:text-base">
          {{ faq.answer }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

library.add(faChevronDown);

export default {
  name: "FaqSection",
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      faqs: [
        { question: "Como é possível que você garanta resultado?", answer: "Nós garantimos resultado por meio de estratégias personalizadas..." },
        { question: "Quais as formas de pagamento?", answer: "Aceitamos cartão de crédito, débito, PIX e boletos bancários..." },
        { question: "Qual plano escolher?", answer: "Escolha o plano que mais se adequa ao tamanho do seu negócio..." },
        { question: "Como cancelar o plano?", answer: "Para cancelar o plano, basta acessar a sua conta e clicar em cancelar..." },
        { question: "Como faço para entrar em contato?", answer: "Você pode entrar em contato conosco por meio do chat, e-mail ou telefone..." },
        { question: "Como faço para alterar meus dados?", answer: "Para alterar seus dados, basta acessar a sua conta e clicar em editar..." },
      ],
      openIndex: null,
    };
  },
  methods: {
    toggleFaq(index) {
      this.openIndex = this.openIndex === index ? null : index;
    },
  },
};
</script>

<style scoped>
.faq-section {
  background-color: #000000;
  padding: 2rem 1rem;
}

.faq-container {
  max-width: 40rem;
  margin: 0 auto;
}

.faq-item {
  transition: all 0.3s ease;
}

.faq-item:hover {
  box-shadow: 0 4px 10px rgba(255, 165, 0, 0.4);
}

.faq-question {
  background-color: transparent;
  cursor: pointer;
}

.faq-answer {
  border-top: 1px solid #ff5722;
  overflow-wrap: break-word;
}

@media (min-width: 768px) {
  .faq-section {
    padding: 6rem 2rem;
  }

  .faq-question span {
    font-size: 1.25rem;
  }
}

@media (max-width: 768px) {
  .faq-container {
    padding: 0 1rem;
  }

  .faq-question span {
    font-size: 1rem;
  }

  .faq-answer {
    font-size: 0.875rem;
  }
}
</style>
