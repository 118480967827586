<template>
  <section class="relative overflow-hidden bg-gray-50">
    <div class="relative"
      style="background-image: url('/Fundo 3.png'); background-size: cover; background-position: center;">
      <div
        class="max-w-screen-md mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 items-center py-16 px-4 sm:px-6 lg:px-20">
        <div class="space-y-6 text-left opacity-0 transform translate-y-10 animate-fadeIn order-last md:order-first">
          <h2 class="text-2xl sm:text-3xl font-bold text-gray-800">
            Descubra nossas funcionalidades.
          </h2>
          <div class="space-y-4">
            <p>
              <span class="font-semibold text-orange-600 text-lg">
                Presencial
              </span>
            </p>
            <p class="text-base">
              <span class="font-bold">Atendimento à mesa:</span>
              Receba pedidos de forma rápida e fácil com nosso app.
            </p>
            <p class="text-base">
              <span class="font-bold">Conexão direta com a cozinha:</span>
              Os pedidos são feitos automaticamente para agilizar a produção.
            </p>
            <p class="text-base">
              <span class="font-bold">Gerenciamento completo:</span>
              Acompanhe cada etapa, desde o pedido até a entrega.
            </p>
          </div>
          <div class="flex justify-start">
            <button
              class="px-6 py-3 bg-orange-500 text-white font-semibold shadow-lg transform transition-transform duration-300 hover:bg-orange-600 hover:scale-105">
              Experimente Gratuitamente
            </button>
          </div>
        </div>
        <div
          class="relative flex justify-center items-center opacity-0 transform translate-y-10 animate-fadeIn delay-200 order-first md:order-last">
          <img src="/Foto 3.png" alt="Presencial"
            class="w-full h-auto object-cover rounded-lg shadow-lg hover:scale-105 transition-transform duration-500" />
        </div>
      </div>
    </div>

    <div class="max-w-screen-md mx-auto mt-16 grid grid-cols-1 md:grid-cols-2 gap-8 items-center px-4 sm:px-6 lg:px-20">
      <div
        class="relative flex justify-center items-center opacity-0 transform translate-y-10 animate-fadeIn delay-400 order-last md:order-first">
        <img src="/Foto 4.png" alt="Online"
          class="w-full h-auto object-cover rounded-lg shadow-lg hover:scale-105 transition-transform duration-500" />
      </div>
      <div
        class="space-y-6 text-left opacity-0 transform translate-y-10 animate-fadeIn delay-600 order-first md:order-last">
        <h2 class="text-2xl sm:text-3xl font-bold text-gray-800">
          Descubra nossas funcionalidades.
        </h2>
        <div class="space-y-4">
          <p>
            <span class="font-semibold text-orange-600 text-lg">Online</span>
          </p>
          <p class="text-base">
            <span class="font-bold">Integração de Marketplaces:</span>
            Conecte-se facilmente ao iFood.
          </p>
          <p class="text-base">
            <span class="font-bold">Atendimento centralizado:</span>
            Gerencie todas as interações com clientes em um único lugar.
          </p>
          <p class="text-base">
            <span class="font-bold">Monitoramento em tempo real:</span>
            Acompanhe seus entregadores e otimize o tempo de entrega.
          </p>
          <p class="text-base">
            <span class="font-bold">Relatórios detalhados:</span>
            Acesse insights essenciais para melhorar a eficiência e o desempenho da entrega.
          </p>
        </div>
        <div class="flex justify-start">
          <button
            class="px-6 py-3 bg-orange-500 text-white font-semibold shadow-lg transform transition-transform duration-300 hover:bg-orange-600 hover:scale-105">
            Experimente Gratuitamente
          </button>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "FunctionalitiesSection",
};
</script>

<style scoped>
@keyframes fadeIn {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 0.8s forwards;
}

.delay-200 {
  animation-delay: 0.2s;
}

.delay-400 {
  animation-delay: 0.4s;
}

.delay-600 {
  animation-delay: 0.6s;
}
</style>

