<template>
  <section class="background-section"></section>
</template>

<script>
export default {
  name: 'BackgroundSection',
};
</script>

<style scoped>
.background-section {
  background-image: url('@/assets/Fundo 9.png');
  background-size: contain;
  background-position: center top;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  padding-top: 5rem;

  @media (max-width: 1020px) {
    background-size: 150%;
      background-position: center center;
      height: auto;
      min-height: 100vh;
      padding-top: 4rem;
      margin-bottom: -10px;
    }
    
    @media (max-width: 480px) {
      background-size: 200%;
      background-position: center center;
    height: auto;
    padding-top: 3rem;
  }
}
</style>
