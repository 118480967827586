<template>
  <div :class="['plan-card', bgColor]" @mouseover="hover = true" @mouseleave="hover = false">
    <div class="card-header">
      <div :class="['icon', iconColor]">
        <font-awesome-icon :icon="['fas', iconName]" class="text-xl" />
      </div>
      <span v-if="recommended" class="recommended-badge">
        + Indicado
      </span>
    </div>
    <h3 class="plan-title">{{ title }}</h3>
    <p class="plan-description">{{ description }}</p>
    <h4 class="features-title">O que está incluso?</h4>
    <ul class="features-list">
      <li v-for="(feature, index) in features" :key="index" class="feature-item">
        <font-awesome-icon :icon="['fas', 'check-circle']" :class="['feature-icon', textColor]" />
        {{ feature }}
      </li>
    </ul>
    <button :class="['plan-button', buttonClass]">{{ buttonText }}</button>
  </div>
</template>

<script>
export default {
  name: 'PlanCard',
  props: {
    title: String,
    description: String,
    features: Array,
    buttonText: String,
    recommended: {
      type: Boolean,
      default: false,
    },
    iconName: {
      type: String,
      default: 'th-large',
    },
    iconColor: String,
    textColor: String,
    buttonClass: String,
    bgColor: {
      type: String,
      default: 'bg-white text-gray-800',
    },
  },
  data() {
    return {
      hover: false,
    };
  },
};
</script>

<style scoped>
.plan-card {
  padding: 2rem;
  border-radius: 1.5rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  position: relative;
}

.plan-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.2);
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.recommended-badge {
  background-color: #f97316;
  color: #fff;
  font-size: 0.75rem;
  font-weight: bold;
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

.plan-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-top: 1.5rem;
  text-align: center;
}

.plan-description {
  margin: 1rem 0;
  text-align: center;
}

.features-title {
  font-weight: 600;
  margin-top: 1rem;
}

.features-list {
  list-style: none;
  padding: 0;
  margin: 1rem 0;
}

.feature-item {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.feature-icon {
  margin-right: 0.5rem;
}

.plan-button {
  width: 100%;
  padding: 0.75rem;
  border-radius: 9999px;
  font-weight: bold;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.plan-button:hover {
  background-color: #ea580c;
  transform: translateY(-2px);
}

.btn-primary {
  background-color: #f97316;
  color: #fff;
}

.btn-primary:hover {
  background-color: #ea580c;
}

.btn-secondary {
  background-color: #27272A;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #374151;
}

@media (max-width: 768px) {
  .plan-title {
    font-size: 1.5rem;
  }

  .features-title {
    font-size: 1rem;
  }

  .feature-item {
    font-size: 0.875rem;
  }
}
</style>
