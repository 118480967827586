import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import PoliticaPrivacidade from '@/views/PoliticaPrivacidade.vue';

const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/politicaprivacidade',
        name: 'PoliticadePrivacidade',
        component: PoliticaPrivacidade,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
