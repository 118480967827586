<template>
    <div>
        <a href="/"
            class="fixed bottom-5 left-5 bg-orange-500 text-white rounded-full p-4 shadow-lg hover:bg-orange-600 transition duration-300 flex items-center justify-center w-16 h-16"
            title="Página Inicial">
            <i class="fas fa-home text-2xl"></i>
        </a>
    </div>
</template>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>