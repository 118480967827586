<template>
  <header class="bg-white border-b border-gray-300 shadow-sm fixed left-0 right-0 top-0 z-50 mx-auto max-w-screen-xl">
    <div class="container px-6 lg:px-16 mx-auto flex items-center justify-between h-16">
      <div class="flex items-center space-x-2">
        <span class="text-xl font-bold text-gray-900">
          OVER<span class="text-orange-500">X</span>
        </span>
      </div>

      <nav class="hidden md:flex flex-wrap justify-center items-center space-x-6">
        <a href="#home"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors border-b-2 border-transparent hover:border-orange-500">Home</a>
        <a href="#funcionalidades"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors border-b-2 border-transparent hover:border-orange-500">Funcionalidades</a>
        <a href="#sobrenos"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors border-b-2 border-transparent hover:border-orange-500">Sobre
          Nós</a>
        <a href="#planos"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors border-b-2 border-transparent hover:border-orange-500">Planos</a>

      </nav>

      <div class="hidden md:flex items-center space-x-4">
        <a href="#experimente"
          class="bg-black text-white font-medium px-4 py-2 rounded-lg hover:bg-gray-800 transition-all">
          Experimente Gratuitamente
        </a>
      </div>

      <div class="md:hidden flex items-center">
        <button @click="toggleMenu" class="text-gray-900 focus:outline-none">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor"
            stroke-width="2">
            <path stroke-linecap="round" stroke-linejoin="round" d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
    </div>

    <div v-if="menuOpen" class="md:hidden bg-white border-t border-gray-200 shadow-sm">
      <nav class="flex flex-col space-y-2 p-4">
        <a href="#home" @click="toggleMenu"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors">Home</a>
        <a href="#funcionalidades" @click="toggleMenu"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors">Funcionalidades</a>
        <a href="#planos" @click="toggleMenu"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors">Planos</a>
        <a href="#sobrenos" @click="toggleMenu"
          class="text-gray-700 hover:text-orange-500 font-medium transition-colors">Sobre Nós</a>
        <a href="#experimente" @click="toggleMenu"
          class="bg-black text-white font-medium px-4 py-2 rounded-lg hover:bg-gray-800 transition-all text-center">
          Experimente Gratuitamente
        </a>
      </nav>
    </div>
  </header>

  <main class="pt-12">
    <section id="home">
      <InicioPage />
      <InicioPage2 />
    </section>

    <section id="funcionalidades">
      <FunçaoPage />
    </section>
    <br />
    <section id="gestao">
      <GestaoPage />

    </section>

    <section id="sobrenos">
      <SobrePage />
      <GestaoPage2 />
    </section>

    <section id="planos">
      <PlanosPage />
      <SobrePage2 />
    </section>

    <section id="contato">
      <FormPage />
    </section>

    <section id="experimente">
      <FaqPage />
    </section>
  </main>
</template>

<script>
import InicioPage from "./Inicio/InicioPage.vue";
import InicioPage2 from "./Inicio/InicioPage2.vue";
import FunçaoPage from "./Funcionalidade/FunçaoPage.vue";
import GestaoPage from "./Gestao/GestaoPage.vue";
import GestaoPage2 from "./Gestao/GestaoPage2.vue";
import SobrePage from "./Sobre/SobrePage.vue";
import PlanosPage from "./Planos/PlanosPage.vue";
import SobrePage2 from "./Sobre/SobrePage2.vue";
import FormPage from "./Form/FormPage.vue";
import FaqPage from "./FAQ/FaqPage.vue";

export default {
  name: "HeaderPage",
  data() {
    return {
      menuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
  },
  components: {
    InicioPage,
    InicioPage2,
    FunçaoPage,
    GestaoPage,
    GestaoPage2,
    SobrePage,
    PlanosPage,
    SobrePage2,
    FaqPage,
    FormPage,
  },
};
</script>

<style>
html {
  scroll-behavior: smooth;
}

header {
  width: 100%;
  margin: 0 auto;
}

.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media (min-width: 768px) and (max-width: 1024px) {
  .container {
    padding: 0 16px;
  }

  nav {
    justify-content: center;
    flex-wrap: wrap;
  }

  header {
    padding: 0.5rem 1rem;
  }
}
</style>
