<template>
  <section class="background-section"></section>
</template>

<script>
export default {
  name: 'BackgroundSection',
};
</script>

<style scoped>
.background-section {
  background-image: url('@/assets/sobre.png');
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    padding-top: 5rem;

    @media (max-width: 1020px) {
      background-size: contain;
      height: auto;
      min-height: 100vh;
      padding-top: 4rem;
      margin-bottom: -400px;
      margin-top: 50px;
    }

    @media (max-width: 480px) {
      background-size: contain;
      height: auto;
      padding-top: 3rem;
    }
  }

</style>
