<template>
    <div class="max-w-5xl mx-auto p-6 bg-white shadow-md rounded-lg overflow-y-auto h-screen">
        <h1 class="text-3xl font-bold mb-6 text-center">Política de Privacidade</h1>

        <section class="mb-8">
            <p class="mb-4">
                A <strong>OVER X TECNOLOGIA LTDA</strong>, Sociedade Empresária Limitada, inscrita no CNPJ/ME sob o nº
                CNPJ nº 54.703.652/0001-11, com sede na Rua Graciano Neves, nº 266, LOJA 01 EDIF Brasília, Centro,
                Vitória/ES, CEP 29015-330, por meio deste documento, vem disponibilizar a sua Política de Privacidade, a
                qual dispõe sobre o Tratamento de Dados Pessoais realizado no âmbito das suas soluções comerciais de
                conexão, atendimento virtual e intermediação de compras e vendas de produtos de estabelecimentos por
                meio de plataforma própria, bem como outras soluções que já existam ou venham a existir (“Serviços da
                OverX”).
            </p>
            <p class="mb-4">
                Inicialmente é informar que esta política se aplica aos estabelecimentos que se cadastram na
                “Plataforma” composta pelo site, aplicativo OverX para Parceiros, aplicativo OverX e demais plataformas
                e serviços oferecidos pela OverX.
            </p>
            <p class="mb-4">
                Este documento tem o objetivo de informar aos visitantes, que desejam utilizar os nossos serviços, a
                nossa política de coleta, uso e divulgação de Informações Pessoais. Assim, a presente Política de
                Privacidade visa regular a forma em que é realizado o Tratamento de Dados Pessoais pela Plataforma
                disponibilizada pela OverX, na qualidade de controladora ou de operadora de dados, nos termos definidos
                pela LGPD, dos Dados Pessoais daqueles que acessam, cadastram-se ou de qualquer forma utilizam os
                Serviços da OverX, seja enquanto cliente desta (“Estabelecimentos”) ou como consumidor dos
                Estabelecimentos (doravante designados em conjunto ou individualmente como “Usuários” ou “Usuário”,
                respectivamente).
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">1. POLÍTICA DE PRIVACIDADE</h2>
            <p class="mb-4">
                Por meio desta Política de Privacidade, o usuário obtém a informação de como os seus dados pessoais são
                utilizados e protegidos nesse site, e quais são os seus direitos. É uma forma de cumprir os deveres de
                transparência e publicidade, previstos, respectivamente, na Lei Geral de Proteção de Dados – LGPD (Lei
                13.709/2018) e na Lei de Acesso à Informação – LAI (Lei 12.527/2011). E, de acordo com essa Política de
                Privacidade, assume-se o dever de utilizar os seus dados apenas para as finalidades públicas necessárias
                e o empregar os meios tecnológicos atuais para garantir a proteção e a segurança dos seus dados
                pessoais.
            </p>
            <p class="mb-4">
                Por meio deste documento, fica declarado ciência de que as operações que correspondam ao aceite desta
                Política poderão ser registradas nos bancos de dados da OverX, com as informações de identificação do
                dispositivo, data e hora em que foram realizadas, podendo tais informações serem utilizadas como prova
                pela OverX, independentemente do cumprimento de qualquer outra formalidade.
            </p>
            <p class="mb-4">
                De acordo com a Lei que versa sobre essa matéria, surgem as seguintes funções:
            </p>
            <ul class="list-disc list-inside mb-4">
                <li><strong>titular:</strong> é a pessoa física detentora dos dados pessoais</li>
                <li><strong>controlador:</strong> é quem decide como os seus dados pessoais serão utilizados.</li>
                <li><strong>operador:</strong> é quem irá utilizar os seus dados de acordo com as instruções do
                    controlador.</li>
            </ul>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">2. TRATAMENTO DE DADOS PESSOAIS</h2>
            <p class="mb-4">
                O tratamento de dados pessoais (ou tratar dados), significa qualquer operação realizada com esses dados,
                por exemplo: coleta, utilização, acesso, processamento, armazenamento, arquivamento, distribuição,
                difusão, controle da informação, eliminação, comunicação e transferência. O dado pessoal é uma
                informação relacionada a uma pessoa humana identificada ou identificável. O dado relativo a uma pessoa
                natural, como é o caso do Responsável Legal, é um dado pessoal.
            </p>
            <p class="mb-4">
                Desta feita, nos termos da LGPD, dependendo da forma de realização do Tratamento de Dados Pessoais, a
                OverX poderá ser enquadrada como Controladora ou Operadora de Dados.
            </p>
            <p class="mb-4">
                A OverX poderá exercer o papel de controlador dos dados pessoais tratados em sua Plataforma, ao decidir
                sobre o tratamento de dados pessoais, atuando em observância aos comandos da legislação aplicável,
                seguindo as disposições contidas nesta Política de Privacidade e conforme descrito na presente
                Declaração.
            </p>
            <p class="mb-4">
                Poderá atuar como operador de Tratamento de Dados Pessoais, quando a OverX realizar, em nome e sob as
                exclusivas instruções dos Estabelecimentos, dando suporte para que os Estabelecimentos possam tomar as
                decisões relacionadas ao Tratamento. Nessa hipótese, a OverX atuará como Operadora de Dados, enquanto os
                Estabelecimentos serão os Controladores.
            </p>
            <p class="mb-4">
                Nas hipóteses em que assumem o papel de Controladores de Dados, os Estabelecimentos serão responsáveis
                por cumprir a legislação aplicável à proteção e privacidade de dados, sobretudo a Lei Geral de Proteção
                de Dados, bem como por garantir aos Titulares dos Dados Pessoais o exercício de seus direitos.
            </p>
            <p class="mb-4">
                Como Controladores, os Estabelecimentos também serão responsáveis por todas as decisões tomadas a partir
                do Tratamento realizado por si ou pela OverX, na condição de Operadora. Assim, a OverX não terá nenhuma
                ingerência sobre a tomada de decisão dos Estabelecimentos.
            </p>
            <p class="mb-4">
                A Overx NÃO será responsabilizada pelas decisões tomadas pelos Estabelecimentos na condição de
                Controladores.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">3. FINALIDADE DE TRATAMENTO DE DADOS</h2>
            <p class="mb-4">
                Primacialmente, o Tratamento de Dados Pessoais dos Usuários terá a finalidade de viabilizar a prestação
                dos Serviços da OverX, por meio da prospecção de potenciais clientes e do cadastramento dos Usuários na
                plataforma, a fim de:
            </p>
            <ul class="list-disc list-inside mb-4">
                <li>realizar os atendimentos virtuais automatizados;</li>
                <li>intermediar a compra e venda entre Usuários dos produtos ofertados na plataforma pelos
                    Estabelecimentos;</li>
                <li>gerar relatórios de dados de compras dos Usuários aos Estabelecimentos, exportação de dados de
                    compras dos Usuários aos Estabelecimentos, análise e definição de perfis de consumo dos Usuários dos
                    Serviços OverX, envio de comunicações e prestação de suporte aos Usuários, dentre outros fins.</li>
            </ul>
            <p class="mb-4">
                A OverX também realizará o Tratamento de Dados Pessoais dos Usuários para o desenvolvimento e
                aprimoramento da tecnologia dos Serviços da OverX e da experiência dos Usuários durante o seu consumo,
                inclusive mediante a realização de pesquisas de experiência e de consumo.
            </p>
            <p class="mb-4">
                Sem prejuízo de manter as finalidades acima expostas, a OverX também poderá manter e compilar, mediante
                técnicas de Anonimização, informações estatísticas relacionadas aos Usuários, com a finalidade do
                aprimoramento dos Serviços da OverX, sobretudo de sua plataforma, desde que tais informações não
                impliquem na identificação dos Dados Pessoais dos Usuários.
            </p>
            <p class="mb-4">
                Importante frisar que a anonimização consiste na utilização de meios técnicos razoáveis e disponíveis no
                momento do tratamento de dados, por meio dos quais um dado perde a possibilidade de associação, direta
                ou indireta, a um indivíduo.
            </p>
            <p class="mb-4">
                Os Dados Pessoais cadastrados de seus Usuários serão usados para o envio de conteúdo informativo ou
                publicidade sobre os Serviços da OverX que possam ser de interesse dos Usuários, bem como de pesquisas,
                campanhas de relacionamento e de marketing, e promoções. Para tanto, poderão ser enviados conteúdos
                personalizados para cada Usuário, relacionados ao seu perfil de consumo e às suas preferências, com base
                na utilização dos Serviços da OverX.
            </p>
            <p class="mb-4">
                Os Dados Pessoais coletados e tratados pela OverX poderão ser destinados à análise de crédito,
                verificação de identidade e outras atividades para verificação e prevenção de fraudes, podendo tais
                informações ser enriquecidas a partir de outras fontes, públicas ou privadas, bem como cruzadas com
                informações de terceiros, como órgãos e empresas de proteção de crédito, por exemplo.
            </p>
            <p class="mb-4">
                Será permitido a coleta os Dados cadastrais dos usuários para contribuir na contratação de novos
                colaboradores, como, por exemplo, a partir do armazenamento de currículos recebidos dos Titulares dos
                Dados Pessoais por meio de formulários de inscrição disponibilizados no website da OverX, mediante a
                obtenção do respectivo consentimento do Titular.
            </p>
            <p class="mb-4">
                A coleta de Dados Pessoais pela OverX poderá ser utilizada para o atendimento de obrigações legais
                dispostas em lei, regulamentações e órgãos governamentais, sobretudo da Autoridade Nacional de Proteção
                de Dados, autoridades fiscais, Poder Judiciário e/ou qualquer outra autoridade competente, bem como para
                sua defesa ou para a proteção e exercício regular de seus direitos em âmbito judicial ou administrativo,
                como, por exemplo, para a cobrança de obrigações não adimplidas na data de seu vencimento.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">4. ADESÃO</h2>
            <p class="mb-4">
                Ao contratar com a OverX e/ou utilizar os seus serviços fica de já declarado ciência e concordância, de
                forma livre e inequívoca, com todas as disposições desta Política de Privacidade. Em caso de NÃO
                concordância com as disposições desta Política de Privacidade, é necessário descontinuar o seu acesso
                e/ou uso dos Serviços da OverX imediatamente.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">5. DIREITOS DOS TITULARES DE DADOS</h2>
            <p class="mb-4">
                Os Usuários, enquanto Titulares dos Dados Pessoais que estiverem sob o controle da OverX, terão
                garantido o exercício dos seguintes direitos:
            </p>
            <ul class="list-disc list-inside mb-4">
                <li><strong>Direito de confirmação de tratamento:</strong> Consiste no direito de poder saber se o
                    sistema realmente está utilizando os seus dados pessoais e quais são eles. Acessar, revisar, saber
                    se realizamos algum tratamento com seus Dados Pessoais e requisitar uma cópia eletrônica da
                    informação que a plataforma possui sobre o usuário.</li>
                <li><strong>Direito de acesso aos seus dados:</strong> Trata-se do direito de acesso e informações aos
                    tratamentos de dados pessoais utilizados na plataforma pelo sistema, implicando na finalidade, forma
                    de tratamento, duração e o compartilhamento.</li>
                <li><strong>Direito de correção de dados:</strong> Caso alguns dos seus dados estejam desatualizados,
                    errados ou ainda incompletos, você tem o direito de solicitar a correção deles.</li>
                <li><strong>Direito de excluir, anonimizar ou bloquear dados:</strong> Poderá corrigir ou solicitar a
                    correção de Dados Pessoais incompletos, inexatos ou desatualizados; bem como solicitar a
                    anonimização, bloqueio ou eliminação de Dados Pessoais desnecessários e excessivos, ou que estejam
                    em desconformidade com a LGPD.</li>
                <li><strong>Direitos relativos ao consentimento:</strong> Quando for solicitado o seu consentimento para
                    tratar os dados pessoais, você terá o direito de requisitar a respectiva exclusão, e, inclusive, até
                    mesmo de revogar (cancelar) o consentimento oferecido anteriormente, fazendo, assim, terminar com o
                    tratamento. Poderão surgir situações em que, por força da LGPD, seja necessário manter os dados
                    pessoais, mesmo que você usuário tenha solicitado a exclusão (art. 16). Quando a atividade de
                    tratamento necessitar do seu consentimento, você pode se negar a consentir. Nesse caso, a OverX
                    informará sobre as consequências da não realização de tal atividade;</li>
                <li><strong>Direito de informação relativo ao compartilhamento:</strong> Você tem o direito de saber
                    quais os órgãos públicos e empresas privadas que tiveram acesso aos seus dados pessoais.</li>
                <li><strong>Direito de Portabilidade:</strong> É direito do usuário solicitar a portabilidade dos Dados
                    Pessoais a outro prestador de serviços similares, desde que respeitadas as informações confidenciais
                    da OverX, dos Estabelecimentos e de terceiros.</li>
            </ul>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">6. COLETA DE DADOS PESSOAIS</h2>
            <p class="mb-4">
                A OverX, quando da interação com os Usuários, seja por meio dos seus serviços, seja por meio de outras
                formas de interação, poderá coletar Dados Pessoais de forma automática ou mediante solicitação de
                informações pessoais identificáveis diretamente aos Usuários, que serão utilizadas conforme as
                finalidades de tratamento previstas nesta Política de Privacidade.
            </p>
            <p class="mb-4">
                Desta feita, para uma melhor experiência no uso de nossos Serviços, especialmente do aplicativo, é
                solicitado o fornecimento de Informações Pessoais de identificação como nome, CPF e e-mail. Também é
                solicitada a autorização de uso de algumas informações do seu dispositivo incluindo, mas não limitada, o
                acesso à câmera, notificações push, geolocalização e também autorização para utilização do Google
                Analytics. As informações solicitadas serão mantidas por nós e usadas como descrito nesta Política de
                Privacidade.
            </p>
            <p class="mb-4">
                A plataforma OverX faz uso de serviços de terceiros que podem coletar informações usadas para
                identificar você ou o seu dispositivo. Assim, o acesso à Política de Privacidade de serviços de
                terceiros usados pelo OverX:
            </p>
            <p class="mb-4">
                Os Dados Pessoais utilizados pela OverX poderão ser coletados:
            </p>
            <ul class="list-disc list-inside mb-4">
                <li>Por meio do acesso e da utilização, pelos Usuários, do website <a href="https://overx"
                        class="text-blue-500 underline">https://overx</a> ou de qualquer outro gerenciado pela ou para a
                    OverX, incluindo todos os domínios/URLs por ela operados e/ou páginas comerciais mantidas em
                    aplicativos de terceiros;</li>
                <li>Por meio da utilização de sites, plataformas ou aplicativos móveis voltados para os Usuários e
                    gerenciados pela ou para a OverX;</li>
                <li>Por meio das interações realizadas pelos setores internos da OverX (comercial, marketing,
                    financeiro, produto, growth, atendimento ao cliente, dentre outros) com os Usuários, por intermédio
                    de interações por telefone, correio eletrônico (e-mail) ou outros meios de comunicação eletrônicos,
                    inclusive através de aplicativos de terceiros (como, por exemplo, por interações realizadas pelo
                    WhatsApp);</li>
                <li>Por meio das interações realizadas entre os Usuários e a OverX por intermédio dos seus canais de
                    atendimento ao cliente, incluindo o Serviço de Atendimento ao Consumidor (SAC), correio eletrônico e
                    telefone disponibilizados para esta finalidade;</li>
                <li>Por meio do preenchimento de formulários impressos ou digitais (online ou offline) pelos Usuários, e
                    formas similares, em websites da OverX ou de terceiros, pelos quais são solicitadas informações
                    sobre Dados Pessoais;</li>
                <li>Por meio de interações dos Usuários com anúncios da OverX, nas situações em que os Usuários
                    interajam com um ou mais anúncios da OverX em sites de terceiros e/ou aplicativos de terceiros;</li>
                <li>Dados Pessoais advindos de, e disponibilizados em, redes sociais e sites de buscas de terceiros
                    (como Facebook, Google e Instagram, por exemplo), fontes públicas de dados e dados adquiridos em
                    virtude de operações de reorganizações societárias da OverX;</li>
                <li>Demais ocasiões em que a OverX possa ter acesso aos Dados Pessoais dos Usuários.</li>
            </ul>

            <h3 class="text-xl font-semibold mb-4">6.1. PROCEDIMENTO DE COLETA DE DADOS PESSOAIS</h3>
            <p class="mb-4">
                Para a interação entre usuário e OverX, necessária será a coleta de diferentes informações dos usuários.
                Para fins de esclarecimentos os dados se classificam em:
            </p>
            <ul class="list-disc list-inside mb-4">
                <li><strong>dados pessoais “comuns”:</strong> servem para identificar o titular. São, por exemplo, o seu
                    nome, o seu CPF, RG, endereço.</li>
                <li><strong>dados pessoais sensíveis:</strong> estão relacionados com a sua intimidade, privacidade e
                    interesses pessoais. De acordo com a LGPD, podem ser: “origem racial ou étnica, convicção religiosa,
                    opinião política, filiação a sindicato ou a organização de caráter religioso, filosófico ou
                    político, dado referente à saúde ou à vida sexual, dado genético ou biométrico” (art. 5º, II).</li>
            </ul>
            <p class="mb-4">
                Conforme previsão legal, os seguintes dados poderão ser coletados:
            </p>
            <ol class="list-decimal list-inside mb-4">
                <li>Informações necessárias para permitir que a OverX estabeleça comunicação ou verifique o seu cadastro
                    e/ou a utilização dos Serviços por ela oferecidos, seja como representante legal de um
                    Estabelecimento, seja como Usuário da Plataforma OverX, tais como nome completo, CPF, documento de
                    identidade (RG), data de nascimento, endereço, e-mail, número de telefone, número de celular, dentre
                    outras;</li>
                <li>Informações que sejam necessárias para que seja dado ao usuário o acesso ao seu perfil de conta
                    específico para utilização dos Serviços da OverX, tais como nome de usuário, senha em formato
                    irrecuperável e/ou pergunta e resposta de segurança;</li>
                <li>Informações que permitam a OverX a compreender o perfil de consumo e preferências dos Usuários por
                    meio da utilização dos Serviços oferecidos, tais como, o histórico de pedidos feitos pelos Usuários;
                </li>
                <li>Qualquer informação sobre o sistema de computador ou outro dispositivo utilizado pelos Usuários para
                    acessar um de sites da OverX, plataformas ou aplicativos, como o endereço IP, o tipo de sistema
                    operacional e o tipo e a versão do navegador da web, data e hora de acesso, buscas realizadas e
                    visualização de produtos cadastrados por estabelecimentos nos Serviços da OverX. Em caso de acesso
                    via site, plataforma ou aplicativo da OverX usando um dispositivo móvel, como smartphone, as
                    informações coletadas também incluirão, sempre que permitido, o ID de dispositivo exclusivo de seu
                    telefone, o ID de publicidade, a localização geográfica e outros dados similares do dispositivo
                    móvel;</li>
                <li>A navegação e interação com nossos sites e plataforma, a OverX utiliza tecnologias de coleta
                    automática de dados para coletar determinadas informações sobre ações dos usuários. Essas
                    informações registram links que serão acessados, quais páginas ou conteúdos houve visualização e por
                    quanto tempo e outras informações e estatísticas semelhantes sobre as interações, como tempo de
                    resposta a conteúdo, erros de download e duração das visitas a determinadas páginas. Essas
                    informações são capturadas por meio de tecnologias automatizadas, como Cookies e web beacons, e via
                    rastreamento de terceiros, conforme descritos no capítulo de dados de navegação;</li>
                <li>Informações compartilhadas voluntariamente pelos Usuários com a OverX sobre sua experiência de uso
                    dos Serviços, bem como as reclamações, pesquisas de satisfação e solicitações de melhorias;</li>
                <li>Informações compartilhadas publicamente em uma rede social de terceiros ou informações que fazem
                    parte do perfil do Usuário em uma rede social de terceiros (como Facebook e Instagram), que incluem
                    suas informações básicas de conta (dados cadastrais, cidade atual, foto de perfil, ID de usuário,
                    lista de amigos etc.) e quaisquer outras informações ou atividades adicionais que o Usuário permita
                    que a rede social de terceiros compartilhe;</li>
                <li>Informações tratadas para atendimentos de pedidos nos Serviços da OverX como detalhes de pedidos,
                    data e hora de pedidos, valores de pedidos, distância de entrega de pedidos, necessários para a
                    contratação dos Serviços ou as utilizadas para fazer uma compra nos Estabelecimentos, semelhantes
                    aos dados de cartão de débito ou crédito (nome do titular do cartão, número do cartão e data de
                    validade) ou outros métodos de pagamento, inclusive por meio de dados bancários (banco, agência,
                    número de conta corrente). Em qualquer caso, a OverX ou nossos prestadores de serviço de
                    processamento de pagamentos lida(m) com as informações financeiras e de pagamento em conformidade
                    com as leis, normas e os padrões de segurança aplicáveis;</li>
                <li>A OverX poderá coletar Dados Pessoais de Usuários por meio do compartilhamento de bases de dados de
                    sistemas, aplicativos e sites integrados à Plataforma da OverX, desde que em conformidade com a
                    legislação de proteção de dados aplicável;</li>
                <li>Comunicações realizadas entre os Usuários durante a utilização dos Serviços da OverX em troca de
                    mensagens realizadas por meio da plataforma ou por meio de aplicativos de terceiros;</li>
                <li>Comunicações com o Serviço de Atendimento ao Consumidor podem ser gravadas ou ouvidas, de acordo com
                    as leis aplicáveis, para necessidades operacionais locais. Em observância à exigência legal, o
                    Usuário será informado sobre tal gravação no início de sua chamada;</li>
                <li>A OverX não coleta diretamente Dados Pessoais Sensíveis de seus Usuários. No entanto, em situações
                    de necessidade de coleta de dados sensíveis, o Usuário será avisado sobre a finalidade e forma de
                    tratamento, de forma específica e destacada, dos Dados Pessoais Sensíveis que serão tratados, por
                    meio de um aviso de privacidade específico e destacado a ser exibido na Plataforma;</li>
                <li>A OverX não realiza diretamente a coleta e o tratamento de Dados Pessoais de crianças e
                    adolescentes. No entanto, caso seja possível a identificação do tratamento de qualquer Dado Pessoal
                    de crianças ou de adolescentes no âmbito da Plataforma, o acesso e a utilização da Plataforma
                    somente poderão prosseguir mediante o fornecimento do consentimento de um dos pais do titular ou de
                    seu representante legal, que será requisitado por correio eletrônico ou outro meio hábil a comprovar
                    o consentimento do responsável legal ou genitor do titular. Dados de crianças e adolescentes serão
                    sempre tratados no melhor interesse destes e somente para a realização e conclusão de pedidos na
                    Plataforma, sendo que qualquer informação de uso ou perfil de consumo do titular não poderá ser
                    armazenada, exceto nos casos em que tais informações passem por processos de Anonimização e
                    desvinculação da identificação do dado com o seu titular.</li>
            </ol>
            <p class="mb-4">
                Desta forma, fica previsto que o tratamento dos dados coletados será realizado mediante o fornecimento
                de consentimento pelo Titular dos Dados Pessoais e versará:
            </p>
            <ul class="list-disc list-inside mb-4">
                <li>a pedido do Titular dos Dados Pessoais será procedida a execução de contratos ou de procedimentos
                    preliminares relacionados ao contrato do qual seja parte o Titular dos Dados Pessoais;</li>
                <li>o cumprimento de obrigações legais e/ou regulatórias;</li>
                <li>o exercício regular de direitos da OverX em processo judicial, administrativo ou arbitral;</li>
                <li>o atendimento aos legítimos interesses da OverX e/ou de terceiros;</li>
                <li>a proteção do crédito.</li>
            </ul>
            <p class="mb-4">
                A OverX, durante a relação comercial mantida com o Estabelecimento, também poderá coletar e tratar dados
                não-pessoais de identificação e de acesso à Plataforma, referentes às informações de identificação do
                Estabelecimento, tais como, mas sem se limitar a, Cadastro Nacional de Pessoa Jurídica (CNPJ), nome
                empresarial, nome fantasia, endereço completo do Estabelecimento, e-mail, telefone, dados bancários
                (como banco, agência, número de conta corrente, dentre outros), informações de login na Plataforma,
                assim como dados não-pessoais referentes ao funcionamento do Estabelecimento, tais como, mas sem se
                limitar a, horário de funcionamento, opções de atendimento, produtos disponibilizados, formas de entrega
                e taxas, formas de pagamento, valores de produtos, áreas de atendimento, dentre outras informações
                necessárias para a prestação dos Serviços da Overx aos Estabelecimentos, conforme previstas nos Termos e
                Condições de Uso da Plataforma.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">7. ARMAZENAMENTO DE DADOS</h2>
            <p class="mb-4">
                Os Dados Pessoais coletados pela OverX serão armazenados em formulários preenchidos pelos Usuários,
                plataforma e aplicativos próprios e/ou de terceiros, e/ou em bancos de dados em nuvem, próprios ou de
                terceiros provedores deste serviço, cujos servidores podem estar localizados no Brasil ou no exterior,
                sendo certo que a OverX sempre buscará a contratação de fornecedores que garantam alto nível de
                segurança, comprometam-se com a privacidade dos dados confiados e que estejam em conformidade com a Lei
                Geral de Proteção de Dados.
            </p>
            <p class="mb-4">
                A OverX manterá os Dados Pessoais dos Usuários armazenados pelo tempo que for necessário para o
                cumprimento das finalidades para as quais eles foram coletados, o qual se prolongará pelo período de até
                12 (doze) meses após o cancelamento da contratação dos Serviços da Overx pelo Estabelecimento, com a
                finalidade de facilitar a experiência deste Estabelecimento, na hipótese de recontratação dos Serviços
                no período.
            </p>
            <p class="mb-4">
                Após o transcurso desse prazo, caso não haja recontratação dos Serviços da OverX pelo Estabelecimento,
                essa poderá manter os Dados Pessoais dos Usuários para o seu uso exclusivo, vedado o acesso de
                terceiros, mediante procedimento de Anonimização, nos termos do art. 16, IV, da Lei Geral de Proteção de
                Dados.
            </p>
            <p class="mb-4">
                Dentre outras hipóteses previstas pelo art. 16 da Lei Geral de Proteção de Dados, ficam excepcionadas
                também as demais hipóteses em que haja razão para a manutenção dos Dados Pessoais dos Usuários, para o
                cumprimento de obrigação legal ou regulatória pela OverX.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">8. COMPARTILHAMENTO DE DADOS</h2>
            <p class="mb-4">
                A fim de viabilizar a prestação de serviços, terceiros prestadores de serviço, poderão ter acesso a
                Dados Pessoais dos Usuários, quando autorizados pela OverX, para finalidades específicas que por ela
                sejam requisitadas, com base em suas instruções e em disposições contratualmente ajustadas.
            </p>
            <p class="mb-4">
                O compartilhamento de Dados Pessoais de Usuários obriga os prestadores de serviço a observar a Lei Geral
                de Proteção de Dados, bem como esta Política de Privacidade e as instruções fornecidas para o Tratamento
                de Dados Pessoais, realizado em nome da OverX.
            </p>
            <p class="mb-4">
                A OverX poderá compartilhar Dados Pessoais dos Usuários com órgãos e empresas terceiras de verificação
                de situação de crédito ou para cobrança de títulos vencidos, respeitada a legislação vigente.
            </p>
            <p class="mb-4">
                Também fica previsto compartilhamento de Dados Pessoais dos Usuários em caso de requisição judicial ou
                administrativa proveniente de autoridade competente para tanto.
            </p>
            <p class="mb-4">
                A OverX também poderá compartilhar Dados Pessoais dos Usuários com demais empresas do seu grupo
                empresarial, existentes ou que venham a ser constituídas, direta ou indiretamente controladas, desde que
                respeitadas as disposições previstas nesta Política de Privacidade.
            </p>
            <p class="mb-4">
                Em caso de reorganização societária da OverX, o compartilhamento de Dados Pessoais dos Usuários com
                outras pessoas relacionadas será permitido, em virtude das modificações da estrutura societária e/ou de
                controle da OverX. Nessas hipóteses, a pessoa que vier a acessar os Dados Pessoais dos Usuários ou
                assumir o controle da OverX estará também vinculada a esta Política de Privacidade, de modo a dar
                continuidade à proteção aos Dados Pessoais dos Usuários, conforme aqui disposto.
            </p>
            <p class="mb-4">
                Em outras situações nas quais haja a necessidade de compartilhamento de Dados Pessoais dos Usuários,
                será solicitado pela OverX o consentimento dos Usuários.
            </p>
            <p class="mb-4">
                Poderão ser compartilhados Dados Pessoais de Usuários com parceiros comerciais e prestadores de serviço
                da OverX sediados fora do país de sua sede. Para regular a prestação dos Serviços da OverX aos Usuários,
                o armazenamento dos dados ficará em servidores em nuvem localizados fora do Brasil.
            </p>
            <p class="mb-4">
                A transferência internacional de dados com terceiros ficará sujeita à observância e obrigação
                contratual, em conformidade com a legislação de proteção de dados aplicável, devendo esta ser de igual
                nível de rigorosidade ou maior com relação à proteção de dados, em comparação à Lei Geral de Proteção de
                Dados. Além disso, os dados transferidos somente poderão ser tratados se observados os termos desta
                Política de Privacidade.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">9. PROTEÇÃO DE DADOS</h2>
            <p class="mb-4">
                A OverX utiliza as medidas adequadas de segurança para manter os Dados Pessoais dos Usuários
                confidenciais e seguros, apenas armazenando os Dados Pessoais dos Usuários em ambientes operacionais que
                usem medidas de segurança razoáveis para prevenir acessos não autorizados e incidentes.
            </p>
            <p class="mb-4">
                Fica ressalvado que a Proteção de Dados Pessoais não se aplica às informações que os Usuários tenham
                escolhido compartilhar com terceiros, sendo os Usuários responsáveis por manter sigilosos e
                confidenciais os seus dados de cadastro e acesso aos sites, plataformas e aplicativos da OverX.
            </p>
            <p class="mb-4">
                A OverX não se obriga a garantir que as medidas de segurança sejam isentas de qualquer tipo de violação
                ou que não estejam sujeitas a interferência de terceiros (hackers, entre outros). Por sua natureza,
                qualquer medida de segurança pode falhar e qualquer dado pode se tornar público em função do vazamento,
                de modo que a OverX não pode prestar nenhuma garantia de que todos e quaisquer acessos não autorizados
                serão impedidos.
            </p>
            <p class="mb-4">
                Portanto, ao consentir com esta Política de Privacidade e/ou ao utilizar Serviços da Overx, fica por
                meio deste, EXPRESSAMENTE reconhecida, por você Usuário, a anuência do risco acima citado, oportunidade
                em que declara concordância em que a OverX não poderá ser responsabilizada nas hipóteses de vazamento de
                dados nas quais os protocolos razoáveis para proteção de dados, apesar de estritamente seguidos, não
                forem suficientes para garantir a segurança contra o vazamento em questão.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">10. DADOS DE NAVEGAÇÃO (COOKIES)</h2>
            <p class="mb-4">
                Havendo qualquer interação entre os Usuários com o site e/ou com a plataforma OverX, bem como com
                publicações por ela realizadas, seja por meio de dispositivos móveis ou não, automaticamente serão
                armazenadas informações coletadas por meio de cookies, pixel tags e outras tecnologias de armazenamento
                de dados e de identificação de usuários, sejam de propriedade da OverX ou de terceiros (por exemplo,
                Facebook Pixel, Google Analytics, Google Ads, Mautic, dentre outros).
            </p>
            <p class="mb-4">
                Fica facultada à OverX a utilização de outras tecnologias de rastreamento, inclusive de endereços IP,
                dados de acesso, arquivos de registro e sinalizadores da web, para fins de auxiliá-la na adaptação de
                seu website e/ou sua plataforma, de modo a atender às preferências específicas de seus Usuários.
            </p>
            <p class="mb-4">
                Os cookies e as outras tecnologias de armazenamento têm por finalidade auxiliar a OverX no
                reconhecimento do Usuário ao acessar o site e/ou a plataforma, entender melhor o seu perfil de
                utilização dos Serviços da OverX, oferecendo uma melhor experiência de navegação, de forma
                personalizada, recomendando produtos e apresentando publicidades e outros itens que possam ser de seu
                interesse.
            </p>
            <p class="mb-4">
                Os cookies são Tecnologias de coleta de informações e armazenagem de preferências, com a finalidade de
                personalização de navegação de acordo com o perfil do Usuário. Também podem ser arquivos com uma pequena
                quantidade de informação que são normalmente usados como identificadores únicos anônimos. São
                armazenados na memória interna do seu dispositivo de acordo com o seu uso do aplicativo. Os Cookies são
                utilizados para garantir o bom funcionamento de sites e demais serviços online, assim como para fornecer
                informações sobre o endereço IP, tipo de navegador, sistema operacional, páginas visitadas, duração da
                visita, entre outras.
            </p>
            <p class="mb-4">
                O Usuário, ao acessar o website e/ou utilizar os Serviços da OverX, concede a sua autorização para a
                utilização de cookies e demais tecnologias de armazenamento, por meio de aceite quando do primeiro
                acesso ao website e/ou da primeira utilização dos Serviços da OverX.
            </p>
            <p class="mb-4">
                O Usuário poderá, a qualquer tempo, desativar ou eliminar os cookies e outras tecnologias armazenadas em
                seu computador ou dispositivo móvel, devendo fazer manualmente por meio das configurações disponíveis em
                seu navegador de internet (Internet Explorer, Google Chrome, Mozilla Firefox, Safari, dentre outros).
            </p>
            <p class="mb-4">
                A exclusão ou desativação de cookies e demais tecnologias de armazenamento poderão tornar indisponíveis
                algumas funcionalidades do website e/ou da plataforma da OverX.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">11. ATUALIZAÇÕES DESTA POLÍTICA DE PRIVACIDADE</h2>
            <p class="mb-4">
                À OverX é reservado o direito de atualizar a Política de Privacidade, periodicamente, assim sendo, você
                deve revisar esta página periodicamente para qualquer mudança. Qualquer atualização nesta Política de
                Privacidade será enviada notificação ao Usuário. Essas atualizações, ou seja, a nova versão da Política
                de Privacidade entrará em vigor imediatamente após a sua publicação no nosso site e terão efeito
                imediatamente após a sua disponibilização nesta página.
            </p>
            <p class="mb-4">
                Caso não haja concordância com qualquer alteração, deverá imediatamente interromper o uso dos Serviços
                da OverX.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">12. DISPOSIÇÕES GERAIS</h2>
            <p class="mb-4">
                Esta Política de Privacidade somente será aplicável às relações de tratamento, proteção e privacidade de
                dados relacionadas aos Serviços da OverX, sendo certo que esta não possui ingerência sobre a forma e
                conteúdo das regras de tratamento, proteção e privacidade de dados de produtos e/ou serviços de
                terceiros, cabendo ao Titular dos Dados Pessoais e a terceiros conhecer e/ou manifestar o seu
                consentimento sobre a forma e conteúdo de tais regras de tratamento, proteção e privacidade de dados de
                produtos e/ou serviços de terceiros.
            </p>
        </section>

        <section class="mb-8">
            <h2 class="text-2xl font-semibold mb-4">13. DISPOSIÇÕES FINAIS</h2>
            <p class="mb-4">
                Para qualquer necessidade de esclarecimentos, solicitações e/ou exercício de direitos dos Titulares dos
                Dados Pessoais, a OverX disponibiliza para fins de proteção de dados os meios para que o usuário possa
                entrar em contato, inclusive para receber reclamações e solicitações, atendendo as demandas que forem
                apresentadas através do formulário contido neste site pelas seguintes informações:
            </p>
            <ul class="list-disc list-inside mb-4">
                <li><strong>Encarregado:</strong> XXX</li>
                <li><strong>E-mail para contato:</strong> XXXXXXX</li>
                <li><strong>Endereço para correspondências:</strong> Rua Graciano Neves, nº 266, LOJA 01 EDIF Brasília,
                    Centro, Vitória/ES, CEP 29015-330.</li>
            </ul>
            <p class="mb-4">
                <strong>Última Atualização:</strong> 25 de setembro de 2024.
            </p>
        </section>
    </div>
    <HomeButton />
</template>

<script>
import HomeButton from '@/components/Buttons/HomeButton.vue';

export default {
    name: "PrivacyPolicy",
    components: {
        HomeButton
    }
};
</script>

<style scoped>
/* Adicione estilos adicionais, se necessário */
</style>