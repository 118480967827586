<template>
  <section class="relative flex flex-col lg:flex-row items-center justify-center bg-center bg-cover rounded-lg overflow-hidden"
           style="background-image: url('/Fundo 1.png'); background-size: 100% 110%; background-repeat: no-repeat;">
    <div class="flex-1 px-4 sm:px-6 md:px-10 lg:px-16 py-4 sm:py-6 lg:py-12 text-center lg:text-left section-text">
      <h1
          class="text-lg sm:text-xl md:text-2xl lg:text-4xl font-bold leading-snug break-words max-w-full text-dynamic animated-text">
        A <span class="highlight">gestão completa</span> do seu estabelecimento e
        delivery <span class="highlight">em um só lugar!</span>
      </h1>
      <p
          class="mt-2 sm:mt-3 md:mt-4 text-xs sm:text-sm md:text-base break-words max-w-full text-dynamic1 animated-text delay-200">
        Descomplique sua operação! Seja no atendimento de mesa, delivery ou cozinha, nós facilitamos a gestão do
        seu negócio.
      </p>
      <a href="#"
         class="mt-3 md:mt-5 inline-block bg-orange-500 text-white text-xs sm:text-sm md:text-base font-medium px-4 sm:px-5 py-2 rounded-lg shadow-lg hover:bg-orange-600 transition-all">
        A Overx Facilita a Gestão do Seu Negócio
      </a>
    </div>

    <div class="flex-1 flex justify-center items-center h-full mt-4 lg:mt-0">
      <img src="/Foto 1.png" alt="OverX"
           class="max-h-[200px] sm:max-h-[300px] md:max-h-[400px] lg:max-h-[500px] w-auto object-contain" />
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
};
</script>

<style scoped>
section {
  background-size: cover;
  background-position: center;
  border-radius: 12px;
}

.text-dynamic {
  color: white;
  position: relative;
}

.text-dynamic1 {
  color: white;
  position: relative;
}

.highlight {
  color: #ff7a00;
  position: relative;
  transition: color 0.3s ease;
}

.highlight:hover {
  color: #ffd700;
  text-decoration: underline;
}

.section-text {
  margin-top: 0;
  transition: margin-top 0.3s ease;
}

@media (max-width: 1068px) {
  section {
    padding: 20px;
  }

  .section-text {
    margin-top: 30px;
  }

  .text-dynamic {
    font-size: 1.25rem;
    color: white;
  }

  .text-dynamic1 {
    font-size: 1rem;
    color: white;
  }

  img {
    max-height: 250px;
  }
}

@media (max-width: 768px) {
  section {
    padding: 16px;
    flex-direction: column;
    text-align: center;
  }

  .section-text {
    margin-top: 70px;
  }

  .text-dynamic {
    font-size: 1rem;
  }

  .text-dynamic1 {
    font-size: 0.875rem;
  }

  img {
    max-height: 200px;
    margin-top: 20px;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animated-text {
  opacity: 0;
  animation: fadeInUp 1s forwards;
}

.animated-text.delay-200 {
  animation-delay: 0.2s;
}

.text-dynamic,
.text-dynamic1 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.text-dynamic,
.text-dynamic1,
.highlight {
  transition: all 0.3s ease;
}
</style>
