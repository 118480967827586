<template>
  <footer class="bg-zinc-800 text-gray-400 py-12">
    <div class="bg-orange-500 h-px w-full"></div>

    <div class="container mx-auto px-6 lg:px-20 mt-4">
      <div class="flex flex-col lg:flex-row justify-between space-y-8 lg:space-y-0 lg:space-x-8">
        <div class="flex-1">
          <h2 class="text-white text-3xl font-bold mb-4">OVER<span class="text-orange-500">X</span></h2>
          <p class="text-sm max-w-md">
            Plataforma de gestão automatizada para o seu estabelecimento e delivery. Aumente a eficiência e reduza
            custos com soluções inovadoras.
          </p>
        </div>

        <div class="flex-1">
          <h3 class="text-white text-xl font-semibold mb-4">Navegação</h3>
          <ul>
            <li class="mb-2">
              <a href="#" class="hover:text-orange-500 transition-colors duration-300">Início</a>
            </li>
            <li class="mb-2">
              <a href="#" class="hover:text-orange-500 transition-colors duration-300">Sobre Nós</a>
            </li>
            <li class="mb-2">
              <a href="#" class="hover:text-orange-500 transition-colors duration-300">Serviços</a>
            </li>
            <li class="mb-2">
              <a href="/politicaprivacidade" class="hover:text-orange-500 transition-colors duration-300">Política de
                Privacidade</a>
            </li>
          </ul>
        </div>

        <div class="flex-1">
          <h3 class="text-white text-xl font-semibold mb-4">Siga-nos</h3>
          <div class="flex space-x-4 justify-center lg:justify-start">
            <a href="#" aria-label="Facebook"
              class="text-gray-400 hover:text-orange-500 transition-colors duration-300">
              <font-awesome-icon :icon="['fab', 'facebook-f']" size="lg" />
            </a>
            <a href="#" aria-label="Twitter" class="text-gray-400 hover:text-orange-500 transition-colors duration-300">
              <font-awesome-icon :icon="['fab', 'twitter']" size="lg" />
            </a>
            <a href="#" aria-label="Instagram"
              class="text-gray-400 hover:text-orange-500 transition-colors duration-300">
              <font-awesome-icon :icon="['fab', 'instagram']" size="lg" />
            </a>
            <a href="#" aria-label="LinkedIn"
              class="text-gray-400 hover:text-orange-500 transition-colors duration-300">
              <font-awesome-icon :icon="['fab', 'linkedin-in']" size="lg" />
            </a>
          </div>
        </div>
      </div>

      <div class="border-t border-gray-700 mt-12 pt-4 text-center text-sm">
        <p>&copy; 2024 OVERX. Todos os direitos reservados.</p>
      </div>
    </div>
  </footer>
</template>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';

library.add(faFacebookF, faTwitter, faInstagram, faLinkedinIn);

export default {
  name: "FooterPage",
  components: {
    FontAwesomeIcon,
  },
};
</script>

<style scoped>
.container {
  max-width: 1200px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

input::placeholder {
  color: #a1a1aa;
}

@media (max-width: 1024px) {
  .flex-1:last-child .flex {
    justify-content: center;
  }
}

form {
  width: 100%;
}

input {
  flex: 1;
}

button {
  flex-shrink: 0;
}
</style>
