<template>
  <section class="bg-white text-black py-16 rounded-lg">
    <div class="container mx-auto flex flex-col items-center">
      <h2 class="text-3xl font-bold mb-6 text-center">A gestão ideal para o seu negócio</h2>
    </div>

    <div class="container mx-auto mt-12 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
      <div
          v-for="(testimonial, index) in testimonials"
          :key="index"
          class="flex flex-col items-center justify-center border border-orange-500 rounded-md p-4 sm:p-6 space-y-3 sm:space-y-4 hover:shadow-lg transition-all"
      >
        <font-awesome-icon :icon="testimonial.icon" class="h-10 w-10 sm:h-12 sm:w-12"></font-awesome-icon>
        <p class="font-semibold text-sm sm:text-lg">{{ testimonial.category }}</p>
        <p class="text-center text-xs sm:text-base">{{ testimonial.text }}</p>
        <div class="flex">
          <font-awesome-icon
              v-for="n in 5"
              :key="n"
              icon="star"
              class="text-yellow-500"
              :class="{ 'opacity-50': n > testimonial.rating }"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {
  faUtensils,
  faTruck,
  faStoreAlt,
  faHotdog,
  faHamburger,
  faStar,
} from '@fortawesome/free-solid-svg-icons'

library.add(faUtensils, faTruck, faStoreAlt, faHotdog, faHamburger, faStar)

export default {
  name: 'BusinessManagementSection',
  components: {
    FontAwesomeIcon,
  },
  data() {
    return {
      testimonials: [
        {
          category: 'RESTAURANTE',
          text: '“Excelente serviço, aumentou a eficiência do meu restaurante em 50%!”',
          rating: 5,
        },
        {
          category: 'DELIVERY',
          text: '“Agora consigo gerenciar minhas entregas de forma muito mais eficaz.”',
          rating: 4,
        },
        {
          category: 'DARK KITCHENS',
          text: '“A plataforma perfeita para cozinhas ocultas como a minha.”',
          rating: 5,
        },
      ],
    }
  },
}
</script>

<style scoped>
.container {
  max-width: 1200px;
  padding: 0 1rem;
}

button {
  transition: all 0.3s ease;
}

.opacity-50 {
  opacity: 0.5;
}

@media (max-width: 768px) {
  .container {
    padding: 0 0.5rem;
  }

  .grid-cols-1 {
    grid-template-columns: 1fr;
  }

  .rounded-md {
    border-radius: 0.5rem;
  }

  .p-4 {
    padding: 1rem;
    }
    
    .h-10 {
      height: 2.5rem;
      width: 2.5rem;
    }
    
    .text-sm {
      font-size: 0.875rem;
  }

  .text-xs {
    font-size: 0.75rem;
  }
}
</style>
