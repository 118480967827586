<template>
    <div>
        <a href="https://wa.me/77888888888?text=Olá!%20Gostaria%20de%20mais%20informações."
            class="fixed bottom-5 left-5 bg-green-500 text-white rounded-full p-4 shadow-lg hover:bg-green-600 transition duration-300 flex items-center justify-center w-16 h-16"
            target="_blank" rel="noopener noreferrer">
            <i class="fab fa-whatsapp text-2xl"></i>
        </a>
        <button @click="scrollToTop"
            class="fixed bottom-5 right-5 bg-orange-500 text-white rounded-full p-4 shadow-lg hover:bg-yellow-600 transition duration-300 flex items-center justify-center w-16 h-16">
            <i class="fas fa-arrow-up text-2xl"></i>
        </button>
    </div>
</template>

<script setup>
function scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
}
</script>

<style scoped>
@import '~@fortawesome/fontawesome-free/css/all.css';
</style>