<template>
  <section class="relative flex flex-col items-center bg-center rounded-lg overflow-hidden"
    style="background-image: url('/Fundo 2.png'); background-size: 100% 110%; background-repeat: no-repeat;">
    <div class="w-full text-center py-20 px-6 lg:px-20">
      <h1 class="text-lg sm:text-xl md:text-2xl lg:text-4xl font-bold leading-snug text-dynamic">
        Plataforma tudo em um
        <span class="text-orange-500">para gestão automatizada</span> do seu
        estabelecimento e delivery
      </h1>
    </div>

    <div class="relative w-full px-6 lg:px-20 pb-12">
      <div class="flex items-center">
        <button @click="prevSlide"
          class="flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-white bg-opacity-80 text-gray-800 hover:bg-opacity-100 focus:outline-none">
          <font-awesome-icon :icon="['fas', 'chevron-left']" />
        </button>

        <div ref="carousel"
          class="carousel flex overflow-x-scroll snap-x snap-mandatory scrollbar-hide space-x-4 sm:space-x-6 px-2 sm:px-4">
          <div class="card flex-shrink-0 w-[90%] sm:w-[70%] md:w-[calc(50%-12px)] snap-center">
            <div class="card-icon text-orange-500">
              <font-awesome-icon :icon="['fas', 'cogs']" />
            </div>
            <h2 class="card-title">Gestão completa em um só lugar</h2>
            <p class="card-text">
              Diga adeus ao uso de papéis, planilhas e diversas plataformas.
              Tenha tudo centralizado e integrado com nossos Apps.
            </p>
          </div>

          <div class="card flex-shrink-0 w-[90%] sm:w-[70%] md:w-[calc(50%-12px)] snap-center">
            <div class="card-icon text-green-500">
              <font-awesome-icon :icon="['fab', 'whatsapp']" />
            </div>
            <h2 class="card-title">Atendimento via WhatsApp</h2>
            <p class="card-text">
              Automatizamos a comunicação via Chatbot e notificamos em tempo real sobre o status do pedido.
            </p>
          </div>

          <div class="card flex-shrink-0 w-[90%] sm:w-[70%] md:w-[calc(50%-12px)] snap-center">
            <div class="card-icon text-red-500">
              <font-awesome-icon :icon="['fas', 'map-marker-alt']" />
            </div>
            <h2 class="card-title">Controle total de sua Logistica</h2>
            <p class="card-text">Roteirização automática, monitoramento dos entregadores, chat entre clientes e motoboy
            </p>
          </div>

          <div class="card flex-shrink-0 w-[90%] sm:w-[70%] md:w-[calc(50%-12px)] snap-center">
            <div class="card-icon text-blue-500">
              <font-awesome-icon :icon="['fas', 'chart-line']" />
            </div>
            <h2 class="card-title">Ferramenta de Fidelização</h2>
            <p class="card-text">
              -Gamificação - Cupons de desconto - Cartão fidelidade - Recuperador de vendas - Cardapio Digital com
              Memoria de Pedidos
            </p>
          </div>

          <div class="card flex-shrink-0 w-[90%] sm:w-[70%] md:w-[calc(50%-12px)] snap-center">
            <div class="card-icon text-yellow-500">
              <font-awesome-icon :icon="['fas', 'headset']" />
            </div>
            <h2 class="card-title">Comunicação Centralizada</h2>
            <p class="card-text">
              -Empresa e motoboy
              - Motoboy e cliente
            </p>
          </div>

          <div class="card flex-shrink-0 w-[90%] sm:w-[70%] md:w-[calc(50%-12px)] snap-center">
            <div class="card-icon text-purple-500">
              <font-awesome-icon :icon="['fas', 'file-alt']" />
            </div>
            <h2 class="card-title">Tomada de Decisão Inteligente</h2>
            <p class="card-text">
              Tenha na palma da mão relatórios detalhados de toda operação, finanças e logistica.
            </p>
          </div>
        </div>

        <button @click="nextSlide"
          class="flex items-center justify-center w-8 h-8 sm:w-10 sm:h-10 rounded-full bg-white bg-opacity-80 text-gray-800 hover:bg-opacity-100 focus:outline-none">
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HeroSection",
  methods: {
    nextSlide() {
      const carousel = this.$refs.carousel;
      const card = carousel.querySelector(".card");
      const cardWidth = card.offsetWidth + 16; 
      const carouselWidth = carousel.offsetWidth;
      const visibleCards = Math.max(
          1,
          Math.floor(carouselWidth / cardWidth)
      );
      const scrollAmount = cardWidth * visibleCards; 
      carousel.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    },
    prevSlide() {
      const carousel = this.$refs.carousel;
      const card = carousel.querySelector(".card");
      const cardWidth = card.offsetWidth + 16; 
      const carouselWidth = carousel.offsetWidth;
      const visibleCards = Math.max(
          1,
          Math.floor(carouselWidth / cardWidth)
      );
      const scrollAmount = cardWidth * visibleCards; 
      carousel.scrollBy({
        left: -scrollAmount,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped>
section {
  background-size: cover;
  background-position: center;
  border-radius: 24px;
  overflow: hidden;
  min-height: 600px;
}

.text-dynamic {
  color: white;
}

@media (max-width: 768px) {
  .text-dynamic {
    color: black;
  }
}

h1,
p {
  word-wrap: break-word;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}
.scrollbar-hide {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.card {
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  padding: 20px;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  max-width: 280px;
  margin: 0 auto;
}

.card:hover {
  transform: translateY(-6px);
  box-shadow: 0 12px 16px rgba(0, 0, 0, 0.15);
}

.card-icon {
  font-size: 36px;
  margin-bottom: 12px;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
}

.card-text {
  font-size: 14px;
  line-height: 1.5;
  color: #f0f0f0;
}

@media (max-width: 640px) {
  .card {
    padding: 16px;
    max-width: 240px;
  }
  .card-icon {
    font-size: 32px;
    margin-bottom: 10px;
  }
  .card-title {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .card-text {
    font-size: 13px;
  }
  .text-dynamic {
    color: white;
  }
}

.carousel {
  padding-left: 8px;
  padding-right: 8px;
}

@media (min-width: 768px) {
  .carousel {
    padding-left: 32px;
    padding-right: 32px;
  }
}
</style>
