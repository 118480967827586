<template>
  <section class="flex items-center justify-center py-12 bg-zinc-800 px-4">
    <div class="max-w-lg w-full">
      <h2 class="text-center text-xl md:text-3xl lg:text-4xl font-bold mb-10 text-white">
        Agende uma demonstração
      </h2>
      <form @submit.prevent="handleSubmit" class="space-y-4">
        <div>
          <input type="text" v-model="form.name" placeholder="Nome"
            class="w-full px-4 py-3 border border-orange-500 text-white bg-zinc-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 rounded-lg"
            required />
        </div>
        <div>
          <input type="email" v-model="form.email" placeholder="E-mail"
            class="w-full px-4 py-3 border border-orange-500 text-white bg-zinc-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 rounded-lg"
            required />
        </div>
        <div>
          <input type="tel" v-model="form.phone" placeholder="Telefone"
            class="w-full px-4 py-3 border border-orange-500 text-white bg-zinc-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 rounded-lg"
            required />
        </div>
        <div>
          <textarea v-model="form.message" placeholder="Mensagem" rows="4"
            class="w-full px-4 py-3 border border-orange-500 text-white bg-zinc-800 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-orange-500 resize-none rounded-lg"
            required></textarea>
        </div>
        <button type="submit"
          class="w-full py-3 bg-transparent border border-orange-500 text-orange-500 font-semibold hover:bg-orange-500 hover:text-white transition duration-300 rounded-lg">
          Enviar
        </button>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactForm",
  data() {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      const whatsappNumber = "5531987654321";
      const message = `Olá, meu nome é ${this.form.name}. Meu email é ${this.form.email}. Meu telefone é ${this.form.phone}. Aqui está minha mensagem: ${this.form.message}`;

      const whatsappUrl = `https://wa.me/${whatsappNumber}?text=${encodeURIComponent(
        message
      )}`;

      window.open(whatsappUrl, "_blank");

      this.form.name = "";
      this.form.email = "";
      this.form.phone = "";
      this.form.message = "";
    },
  },
};
</script>

